
import dialogShell from '@/components/common/dialog-shell/index.vue';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import { community } from '@/data';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import { limitErrorImg } from '@/methods/basicFun';
import { getProjectData } from './util';

export default defineComponent({
    components: {
        dialogShell,
        listPagination,
        EnlargeImage
    },
    props: {
        tempKey: {
            type: String,
            required: true
        },
        tempKeyId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        // 办公没有apt相关
        watch(community.ProjectType, () => {
            const projectData = getProjectData()[community.ProjectType.value];
            headers.value = projectData.header;
        }, {
            immediate: true
        });
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'v3/web/common/log/getDoorLogForPM',
            param: {
                TmpKey: props.tempKey,
                TempKeyID: props.tempKeyId
            }
        });

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };

        return {
            updateToList,
            listRequestData,
            headers,
            picAddress,
            showEnlargeImage,
            isShowEnlargeImage,
            setAllImg
        };
    }
});

